import {GroupedRabbitBrokerTimelineEvent, RabbitBrokerTimelineEvent} from "../../../types";
import {EC2Instance} from "@amzn/amazonmq-opsconsole-client";
import DedupeExemptionKeywords from "./DedupeExemptionTypes";

function formatEventDate(timestamp: string, timeZone: string = "UTC", locale: string = "en-US"): string {
    return new Date(timestamp).toLocaleString(locale, {
        timeZone: timeZone,
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true, // 12-hour format with AM/PM
    });
}

const groupEventsByMinute = (events: RabbitBrokerTimelineEvent[], isDedupeMode: boolean, brokerInstances: EC2Instance[] | undefined, regionName: string) => {
    return events.reduce((eventsByMinute: Record<string, GroupedRabbitBrokerTimelineEvent[]>, event) => {
        const eventDate = new Date(event.timeStamp);
        eventDate.setUTCSeconds(0);
        eventDate.setUTCMilliseconds(0);
        const minuteTimestampUtc = eventDate.toISOString();
        if (!eventsByMinute[minuteTimestampUtc]) {
            eventsByMinute[minuteTimestampUtc] = [];
        }
        const eventsInThisMinute = eventsByMinute[minuteTimestampUtc];
        const previousEvent = eventsInThisMinute[eventsInThisMinute.length - 1];
        if (isDeducible(isDedupeMode, previousEvent, event)) {
            previousEvent.eventSource += `\n${assembleFullEventSource(brokerInstances, event.eventSource, regionName)}`;

        } else {
            eventsInThisMinute.push({
                eventSource: assembleFullEventSource(brokerInstances, event.eventSource, regionName),
                eventContent: event.eventContent,
                timeStamp: minuteTimestampUtc,
            });
        }
        return eventsByMinute;
    }, {});
};

const isDeducible = (isDedupeMode: boolean, prevEvent: GroupedRabbitBrokerTimelineEvent | undefined, currentEvent: RabbitBrokerTimelineEvent) => {
    return isDedupeMode
    && prevEvent
    && prevEvent.eventContent === currentEvent.eventContent
    && !(currentEvent.eventContent.toLowerCase().includes(DedupeExemptionKeywords.MAINTENANCE_MODE))
}

function assembleFullEventSource(brokerInstances: EC2Instance[] | undefined, eventSource: string, regionName: string) : string{
    if (brokerInstances) {
        const instance = brokerInstances.find(instance => instance.instanceId === eventSource);
        if (instance) {
            const nodeId = instance.privateIp;
            return `${eventSource} \n rabbit@ip-${nodeId.replace(/\./g, "-")}.${regionName}.compute.internal \n`;
        }
    }
    return eventSource;
}

export const renderGroupedEvents = (brokerInstances:EC2Instance[] | undefined, regionName: string, brokerTimelineEvents: RabbitBrokerTimelineEvent[] | undefined, isDedupeMode: boolean) => {
    if (!brokerTimelineEvents) return [];
    const groupedTimeline = groupEventsByMinute(brokerTimelineEvents, isDedupeMode, brokerInstances, regionName);

    return Object.entries(groupedTimeline).flatMap(([minuteTimestampUtc, events], index) => [
        {
            timeStamp: formatEventDate(minuteTimestampUtc),
            eventSource: events[0].eventSource,
            eventContent: events[0].eventContent,
            isGroupHeader: true,
        },
        ...events.slice(1).map((event) => ({
            eventSource: event.eventSource,
            eventContent: event.eventContent,
            isGroupHeader: false,
        })),
    ]);
};