import { AppLayout, ContentLayout, Flashbar, SideNavigation, SideNavigationProps, Spinner } from '@amzn/awsui-components-react/polaris';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BrokerDetails from './SubPages/BrokerDetails/BrokerDetails';
import ServiceMetrics from './SubPages/ServiceMetrics/ServiceMetrics';
import PageNotFound from '../PageNotFound';
import ServiceMetricsOld from './SubPages/ServiceMetricsOld/ServiceMetricsOld';
import BrokerQueues from './SubPages/BrokerQueues/BrokerQueues';
import SimIssues from './SubPages/SimIssues/SimIssues';
import BrokerWorkflows from './SubPages/BrokerWorkflows/BrokerWorkflows';
import HOTWExecutions from './SubPages/HotwExecutions/HotwExecutions';
import EventHistory from './SubPages/EventHistory/EventHistory';
import FailoverHistory from './SubPages/FailoverHistory/FailoverHistory';
import ReachoutTemplates from './SubPages/ReachoutTemplates/ReachoutTemplates';
import CommandTemplates from './SubPages/CommandTemplates/CommandTemplates';
import ClusterStatus from './SubPages/ClusterStatus/ClusterStatus';
import { buildHostForDomainAndRegion, getDomainFromHost, getRegionFromHost } from '../utils';
import * as Arn from "@aws-sdk/util-arn-parser";
import { InvalidIdentityTokenException } from '@aws-sdk/client-sts';
import { getBrokerById, getBrokerInstances } from '../api/api';
import BrokerPolicies from './SubPages/BrokerPolicies/BrokerPolicies';
import BrokerHeader from './Components/BrokerHeader';
import BrokerEnvironmentPage from './SubPages/BrokerEnvironment/BrokerEnvironment';
import Logs from './SubPages/Logs/Logs';
import CustomerMetricsPage from './SubPages/CustomerMetrics/CustomerMetricsPage';
import { isConfigOverrideSupported } from '../features/features';
import BrokerConfigOverride from './SubPages/BrokerConfigOverride/BrokerConfigOverride';
import { AppContext } from '../App';
import { Broker, BrokerEngineType, EC2Instance, GetBrokerCommandOutput, ResourceNotFoundException } from '@amzn/amazonmq-opsconsole-client';
import BrokerUnresponsiveQueues from './SubPages/BrokerUnresponsiveQueues/BrokerUnresponsiveQueues';
import BrokerTimeline from "./SubPages/BrokerTimeline/BrokerTimeline";
import CodeDeploy from './SubPages/CodeDeploy/CodeDeploy';

const BrokerPage : React.FC = () => {

    const navigate = useNavigate();
    const appState = useContext(AppContext);
    const [broker, setBroker] = useState<Broker | undefined>(undefined);
    const [brokerInstances, setBrokerInstances] = useState<EC2Instance[] | undefined>(undefined);
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    let { brokerId, subPage } : any = useParams();

    useEffect(() => {
        if (!subPage) {
            navigate(`/broker/${brokerId}/details`);
        }
    }, [brokerId, subPage]);

    useEffect(() => {
        appState.setCurrentBroker(broker)
    }, [broker?.id])

    useEffect(() => {
        setError("");
        setBroker(undefined);
        if (brokerId === null) {
            return;
        }
        let domain = getDomainFromHost();
        if (Arn.validate(brokerId)) {
            let arn = Arn.parse(brokerId)
            let region = arn.region;
            if (arn.resource.split(":").length != 3) {
                return;
            }
            let actualBrokerId = arn.resource.split(":")[2]; // resource is broker:{brokerName}:{brokerId}
            let newHost = buildHostForDomainAndRegion(domain, region);
            window.location.href = `https://${newHost}/broker/${actualBrokerId}`
            return;
        }
        let region = getRegionFromHost();
        setLoading(true);
        getBrokerById(brokerId).then((response: GetBrokerCommandOutput) => {
            setBroker(response.broker);
        }).catch((error) => {
            console.log(error);
            if (error instanceof InvalidIdentityTokenException) {
                // this sometimes happens after Midway token expires and user is redirected to Midway login screen.
                console.log("Invalid token, redirecting to home page.");   
                window.location.href = "/";
                return;
            }
            if (error instanceof ResourceNotFoundException) {
                setError("Broker " + brokerId + ` not found in ${domain}/${region}`);
            } else {
                if (error.stacktrace) {
                    setError(error.stacktrace);
                } else {
                    setError("Internal server error. Please try again later.");
                }
            }
        }).finally(() => {
            setLoading(false);
        })
    }, [brokerId]);


    useEffect(() => {
        if (broker === undefined) {
            return;
        }
        getBrokerInstances(broker.id, broker.snapshotId).then(response => {
            setBrokerInstances(response.instances);
        }).catch(error => {
            console.error(error);
        })
    }, [broker?.id]);

    function renderSubPage(subPage: string, broker: Broker) {
        switch(subPage) {
            case 'details': return <BrokerDetails broker={broker} brokerInstances={brokerInstances} />
            case 'service-metrics': return <ServiceMetrics broker={broker} brokerInstances={brokerInstances} />
            case 'service-metrics-old': return <ServiceMetricsOld broker={broker} />
            case 'customer-metrics': return <CustomerMetricsPage broker={broker} />
            case 'logs': return <Logs broker={broker} />
            case 'sim-issues': return <SimIssues broker={broker} />
            case 'workflows': return <BrokerWorkflows broker={broker} />
            case 'hotw-executions': return <HOTWExecutions broker={broker} />
            case 'codedeploy': return <CodeDeploy broker={broker} />
            case 'event-history': return <EventHistory broker={broker} />
            case 'failover-history': return <FailoverHistory broker={broker} />
            case 'reachout-templates': return <ReachoutTemplates broker={broker} />
            case 'command-templates': return <CommandTemplates broker={broker} brokerInstances={brokerInstances} />
            case 'environment': return <BrokerEnvironmentPage broker={broker} />
            case 'queues': return <BrokerQueues broker={broker} brokerInstances={brokerInstances} />
            case 'unresponsive-queues': return <BrokerUnresponsiveQueues broker={broker} brokerInstances={brokerInstances} />
            case 'cluster-status': return <ClusterStatus broker={broker} />
            case 'policies': return <BrokerPolicies broker={broker} />
            case 'config-override': return <BrokerConfigOverride broker={broker} />
            case 'broker-timeline': return <BrokerTimeline broker={broker} brokerInstances={brokerInstances} />
            default: return <PageNotFound />
        }
    }


    let brokerItems:SideNavigationProps.Item[]=[
        { type: 'link', text: `Broker Details`, href: `/broker/${brokerId}/details` },
        { type: 'link', text: `Event History`, href: `/broker/${brokerId}/event-history` },
        { type: 'link', text: `Failover History`, href: `/broker/${brokerId}/failover-history` },
        { type: 'link', text: `HOTW Executions`, href: `/broker/${brokerId}/hotw-executions` },
        { type: 'link', text: `Code Deploy`, href: `/broker/${brokerId}/codedeploy` },
        { type: 'link', text: `Logs`, href: `/broker/${brokerId}/logs` },
        { type: 'link', text: `Service Metrics`, href: `/broker/${brokerId}/service-metrics` },
        { type: 'link', text: `Service Metrics (Old)`, href: `/broker/${brokerId}/service-metrics-old` },
        { type: 'link', text: `Workflows`, href: `/broker/${brokerId}/workflows` },
    ];
    let operationItems:SideNavigationProps.Item[]=[
        { type: 'link', text: `SIM Issues`, href: `/broker/${brokerId}/sim-issues` },
        { type: 'link', text: `Reachout Templates`, href: `/broker/${brokerId}/reachout-templates`},
        { type: 'link', text: `Command Templates`, href: `/broker/${brokerId}/command-templates` },
    ];
    let otherItems:SideNavigationProps.Item[]=[
        { type: 'link', text: `Customer Metrics`, href: `/broker/${brokerId}/customer-metrics` },
    ];

    if (broker?.summary.brokerEngineType === BrokerEngineType.RABBITMQ)  {
        brokerItems.push(...[
            { type: 'link', text: `Broker Environment`, href: `/broker/${brokerId}/environment` },
            { type: 'link', text: `Queues`, href: `/broker/${brokerId}/queues` },
            { type: 'link', text: `Unresponsive Queues`, href: `/broker/${brokerId}/unresponsive-queues` },
            { type: 'link', text: `Cluster Status`, href: `/broker/${brokerId}/cluster-status` },
            { type: 'link', text: `Policies`, href: `/broker/${brokerId}/policies` },
            { type: 'link', text: `Broker Timeline`, href: `/broker/${brokerId}/broker-timeline` },
        ] as SideNavigationProps.Item[])

        if (isConfigOverrideSupported(broker)) {
            otherItems.push(
                { type: 'link', text: `RabbitMQ Config Override`, href: `/broker/${brokerId}/config-override` }
            )
        }
    }
    brokerItems.sort((a,b) => ('text' in a && 'text' in b && a.text>b.text)? 1:-1);
    let navItems: SideNavigationProps.Item[] = [
        { type: 'section', text: 'Broker', items: brokerItems},
        { type: 'divider' },
        { type: 'section', text: 'Operations', items: operationItems },
        { type: 'divider' },
        { type: 'section', text: 'Other', items: otherItems },
        { type: 'divider' },
        { type: 'link', text: `Runbook`, external: true, href: `https://w.amazon.com/bin/view/AmazonMQ/Ops/Runbook` },
    ];

    return (
        <AppLayout
            toolsHide={true}
            headerSelector="#awsui-top-navigation"
            navigationHide={broker === undefined}
            navigation={
                <SideNavigation
                    header={{
                        text: 'Ops Console',
                        href: '#'
                    }}
                    activeHref={`/broker/${brokerId}/${subPage}`}
                    onFollow={(e) => {
                        e.preventDefault();
                        navigate(e.detail.href);
                    }}
                    items={navItems}
                />
              }
            content={
                <ContentLayout>
                    {loading && 
                        <div style={{display: 'flex', alignItems: 'center', position: 'absolute', left: '45%', justifyContent: 'center', height: 'calc(100% - 100px)'}}>
                            <Spinner size='large' />
                        </div>
                    }
                    {!loading && 
                        <>
                            {error !== undefined && error.length > 0 &&
                                <Flashbar items={
                                    [{
                                        "type": "error",
                                        "dismissible": false,
                                        "content": <span style={{whiteSpace: 'pre'}}>{error}</span>
                                    }]
                                }></Flashbar>
                            }
                            {broker === undefined && error === undefined &&
                                <h1>Broker ${brokerId} not found.</h1>
                            }
                            {!error && broker && 
                                <>
                                    <BrokerHeader broker={broker} brokerInstances={brokerInstances} />
                                    {renderSubPage(subPage, broker)}
                                </>
                            }
                        </>
                    }
                </ContentLayout>
            }
        />
    )
}

export default BrokerPage;