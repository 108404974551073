import React, { useEffect, useState } from 'react';
import { getRabbitBrokerTimeline } from '../../../api/api';
import {Broker, EC2Instance} from '@amzn/amazonmq-opsconsole-client';
import {BrokerTimelineEventType, GroupedRabbitBrokerTimelineEvent, RabbitBrokerTimelineEvent} from '../../../types';
import {
    LogStreamConfig,
    GetBrokerTimelineEventCollectionRequest
} from "@amzn/amazonmq-opsconsole-client/dist-types/models/models_0"; // TODO
import {
    Alert, Box,
    Button,
    DateRangePickerProps,
    Flashbar,
    Link,
} from '@amzn/awsui-components-react';
import DateRangePicker from "@amzn/awsui-components-react/polaris/date-range-picker";
import {Tabs} from "@amzn/awsui-components-react/polaris";
import LogTableContent from "./LogTableContent";
import { renderGroupedEvents } from './logEventsUtils';

type Props = {
    broker: Broker,
    brokerInstances: EC2Instance[] | undefined,
}

const BrokerTimeline: React.FC<Props> = ({broker, brokerInstances}) => {
    const [brokerTimelineEvents, setBrokerTimelineEvents] = useState<RabbitBrokerTimelineEvent[] | undefined>(undefined);
    const [brokerTimelineErrors, setBrokerTimelineErrors] = useState<String[] | undefined>(undefined);
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    // Default data range is past 24 hours
    const [dateRange, setDateRange] = useState(() => {
        const endDate = new Date();
        const startDate = new Date(endDate.getTime() - 24 * 60 * 60 * 1000);
        return {
            type: "absolute",
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
        };
    });

    const [hasInteracted, setHasInteracted] = useState(false);
    const brokerId = broker.id;
    const defaultLogStreamConfigs: (LogStreamConfig)[] = [{logStreamName:`${brokerId}\\/.*\\/.*`, instanceId: "*"}]
    const fetchBrokerTimeline = (startTime: Date, endTime: Date) => {
        const request: GetBrokerTimelineEventCollectionRequest = {
            requestType: BrokerTimelineEventType.LOG,
            parameters: {
                logParameters: {
                    logGroupName: "",
                    logStreamConfigs: defaultLogStreamConfigs,
                    messageFilter: "",
                },
            },
        };

        setLoading(true);
        setError('');
        getRabbitBrokerTimeline(broker.id, startTime, endTime, request)
            .then((response) => {
                const sortedEvents = (response.events as RabbitBrokerTimelineEvent[]).sort(
                    (a, b) => new Date(a.timeStamp).getTime() - new Date(b.timeStamp).getTime()
                );
                setBrokerTimelineEvents(sortedEvents);
                setBrokerTimelineErrors(response.errors);
            })
            .catch((error) => {
                console.error(error);
                setError('Could not build broker timeline');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (hasInteracted && dateRange) {
            const startTime = new Date(dateRange.startDate);
            const endTime = new Date(dateRange.endDate);
            fetchBrokerTimeline(startTime, endTime);
        }
    }, [hasInteracted, dateRange]);


    const handleButtonClick = () => {
        if (dateRange) {
            const startTime = new Date(dateRange.startDate);
            const endTime = new Date(dateRange.endDate);
            fetchBrokerTimeline(startTime, endTime);
        }
        setHasInteracted(true);
    };

    const relativeOptions: DateRangePickerProps.RelativeOption[] = [
        {
            key: 'previous-30-minutes',
            amount: 30,
            unit: 'minute',
            type: 'relative',
        },
        {
            key: 'previous-1-hour',
            amount: 1,
            unit: 'hour',
            type: 'relative',
        },
        {
            key: 'previous-6-hours',
            amount: 6,
            unit: 'hour',
            type: 'relative',
        },
        {
            key: 'previous-24-hours',
            amount: 24,
            unit: 'hour',
            type: 'relative',
        },
    ];

    const columnDefinitions = (isDupeMode: boolean) => [
        {
            id: "eventTime",
            header: "Event Time",
            cell: (item: GroupedRabbitBrokerTimelineEvent) =>
                item.isGroupHeader ? (item as { timeStamp: string }).timeStamp : null,
            width: 220,
            minWidth: 220,
            isRowHeader: true,
        },
        {
            id: "eventSource",
            header: "Event Source",
            cell: (item: GroupedRabbitBrokerTimelineEvent) => (
                <span dangerouslySetInnerHTML={{ __html: item.eventSource.replace(/\n/g, '<br />') }} />
            ),
            width: 350,
            minWidth: 350,
        },
        {
            id: "eventContent",
            header: "Event Content",
            cell: (item: GroupedRabbitBrokerTimelineEvent) => item.eventContent,
            width: 600,
            minWidth: 500,
        },
    ];

    const isDupeMode = true;
    const groupedDedupeEvents = renderGroupedEvents(brokerInstances, broker.regionName,brokerTimelineEvents, isDupeMode);
    const groupedNonDedupeEvents = renderGroupedEvents(brokerInstances, broker.regionName, brokerTimelineEvents, !isDupeMode);

    return (
        <div>
            <Alert
                header="Note"
            >
                Events are grouped by minute, ascending order. We include following event types: Cluster Membership
                Changes, Node Maintenance Mode, Node Status, Rabbit Status, Memory Alarm, Disk Alarm. We would also
                appreciate your help if you can help us identify more key log entries in the quip file:
                <Link href={'https://quip-amazon.com/N2pSAZpRNav0/Log-Entries-Examples-for-logCollector'}
                      target='_blank' external={true}>Log Entries Collection</Link>

            </Alert>

            <Alert
                statusIconAriaLabel="Warning" type="warning"
            >
                For the Broker Timeline Tool Experimental Dedupe Mode, we grouped consecutive events with the same content, displaying all sources together and capturing broadcast events. within a one-minute window.

            </Alert>

            <div>
                {brokerTimelineErrors && brokerTimelineErrors.length > 0 && <Flashbar items={
                    [{
                        "type": "error",
                        "dismissible": false,
                        "content": brokerTimelineErrors
                    }]
                }></Flashbar>}
            </div>

            <br/>

            <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                <DateRangePicker
                    onChange={({detail}) => {
                        setHasInteracted(false);
                        setDateRange(detail.value as DateRangePickerProps.AbsoluteValue)
                    }
                    }
                    value={dateRange as DateRangePickerProps.Value}
                    relativeOptions={relativeOptions}
                    isValidRange={(range) => {
                        if (range?.type === "absolute") {
                            const [startDateWithoutTime] = range.startDate.split("T");
                            const [endDateWithoutTime] = range.endDate.split("T");
                            if (!startDateWithoutTime || !endDateWithoutTime) {
                                return {
                                    valid: false,
                                    errorMessage:
                                        "The selected date range is incomplete. Select a start and end date for the date range.",
                                };
                            }
                            if (new Date(range.startDate) > new Date(range.endDate)) {
                                return {
                                    valid: false,
                                    errorMessage:
                                        "The selected date range is invalid. The start date must be before the end date.",
                                };
                            }
                        }
                        return {valid: true};
                    }}
                    i18nStrings={{applyButtonLabel: "Set Time Range"}}
                    absoluteFormat="long-localized"
                    placeholder="Filter by a date and time range"
                    rangeSelectorMode="absolute-only"
                    timeInputFormat="hh:mm"
                    timeOffset={0}
                />
                <Button variant="primary"
                        onClick={handleButtonClick}
                >Build Timeline</Button>
            </div>

            <div>
                {error ? (
                    <h3>{error}</h3>
                ) : (

                    <Tabs
                        tabs={[
                            {
                                label: "Broker Timeline",
                                id: "defaultTab",
                                content: (<LogTableContent
                                    isDupeMode={!isDupeMode}
                                    loading={loading}
                                    hasInteracted={hasInteracted}
                                    columnDefinitions={columnDefinitions}
                                    groupedEvents={groupedNonDedupeEvents}
                                />),
                            },
                            {
                                label: " [Experimental] Broker Timeline Dedupe Mode",
                                id: "dedupeTab",
                                content: (<LogTableContent
                                    isDupeMode={isDupeMode}
                                    loading={loading}
                                    hasInteracted={hasInteracted}
                                    columnDefinitions={columnDefinitions}
                                    groupedEvents={groupedDedupeEvents}
                                />)
                            },
                        ]}
                    />
                )}
            </div>
        </div>
    );
};

export default BrokerTimeline;
