import React, { useContext, useEffect, useState } from 'react';
import { ALL_DOMAINS, Config, Domain, Region } from '../types';
import { useNavigate } from 'react-router';
import { applyMode, Mode } from '@amzn/awsui-global-styles';
import { Input, Select, TopNavigation } from '@amzn/awsui-components-react/polaris';
import { buildHostForDomainAndRegion, buildOldConsoleUrl, generateRegionsOptions, getDomainFromHost, getRegionFromHost } from '../utils';
import { AppContext } from '../App';
const ENTER_KEYCODE = 13;

type Props = {
    config: Config,
    regions: Region[]
}

function getDomains(username: string | undefined): Domain[] {
    if (username === undefined) {
        return ALL_DOMAINS;
    }
    let domains = [...ALL_DOMAINS];
    domains.push({
        "label": `dev-${username}`,
        "id": `dev-${username}`,
    });
    return domains;
}

const Header : React.FC<Props> = ({config, regions}) => {
    
    const context = useContext(AppContext);
    const [brokerId, setBrokerId] = useState<string>("");
    const navigate = useNavigate();
    const [theme, setTheme] = useState<Mode>(localStorage.getItem("THEME") === "Dark" ? Mode.Dark :  Mode.Light);

    useEffect(() => {
        applyTheme(theme);
    }, [theme]);

    const domains = getDomains(config.username);
    const [selectedBrokerDomain, setSelectedBrokerDomain] = 
            useState<Domain | undefined>(getDomains(config.username).find(d => d.id === getDomainFromHost()));
    const [selectedBrokerRegion, setSelectedBrokerRegion] =
            useState<Region | undefined>(regions.find(r => r.regionCode === getRegionFromHost()));

    if (selectedBrokerRegion === undefined || selectedBrokerDomain === undefined) {
        return <></>
    }

    function applyTheme(theme: Mode) {
        localStorage.setItem("THEME", theme === Mode.Dark ? "Dark" : "Light");
        applyMode(theme);
        const iframeElement : HTMLIFrameElement | null = document.getElementById('embedded-cloudwatch-dashboards') as HTMLIFrameElement;
        if (iframeElement && iframeElement.contentWindow) {
            iframeElement.contentWindow.postMessage({
                type: 'colorThemeChanged',
                payload: {
                    colorTheme: theme === Mode.Dark ? "dark" : "light",
                },
            }, iframeElement.src);
        }
        return theme;
      }

    const onChangeThemeClick = () => {
        if (theme === Mode.Dark) {
          setTheme(applyTheme(Mode.Light));
        } else {
          setTheme(applyTheme(Mode.Dark));
        }
      };

    const oldConsoleUrl : string | undefined = buildOldConsoleUrl(context.currentBroker?.id, selectedBrokerDomain.id, selectedBrokerRegion.airportCode);

    return (
        <div className="awsui" id="awsui-top-navigation" style={{position: 'sticky', top: 0, zIndex: 1002}}>
            <TopNavigation
                identity={{
                    href: "/",
                    title: "Amazon MQ",
                }}
                search={
                    <div style={{display: 'flex', flexDirection: 'row', gap: 8, width: 550}}>
                        <Select
                            options={domains.map(d => ({ value: d.id, label: d.label }))}
                            selectedOption={{value: selectedBrokerDomain.id, label: selectedBrokerDomain.label}}
                            onChange={event => {
                                setSelectedBrokerDomain(domains.find(d => d.id === event.detail.selectedOption.value));
                            }}
                        />
                        <Select
                            options={generateRegionsOptions(regions)}
                            filteringType="auto"
                            selectedOption={{value: selectedBrokerRegion.regionCode, label: selectedBrokerRegion.airportCode  + ' (' + selectedBrokerRegion.regionCode + ')'}} onChange={event => {
                                setSelectedBrokerRegion(regions.find(r => r.airportCode.toLowerCase() === event.detail.selectedOption.value));
                            }}
                        />
                        <div style={{flexGrow: 1}}>
                            <Input
                                value={brokerId}
                                type='search'
                                placeholder='Search broker ID/ARN'
                                onKeyDown={event => {
                                    if (event.detail.keyCode === ENTER_KEYCODE) {
                                        let newHost = buildHostForDomainAndRegion(selectedBrokerDomain.id, selectedBrokerRegion.regionCode);
                                        if (window.location.host !== newHost) {
                                            window.location.href = `https://${newHost}/broker/${brokerId}`
                                        } else {
                                            navigate(`/broker/${brokerId}`);
                                        }
                                    }
                                }}
                                onChange={event => {
                                    setBrokerId(event.detail.value)
                                }}
                                autoComplete={false}
                            />
                        </div>
                    </div>
                }
                utilities={[
                    { type: 'button', text: 'Back to old console', href: oldConsoleUrl },
                    { type: 'button', variant: 'link', target: '_blank', text: config.username, href: `https://phonetool.amazon.com/users/${config.username}` },
                    {
                        type: "button",
                        text: theme === Mode.Light ? "Dark" : "Light",
                        onClick: onChangeThemeClick,
                      },
                ]}
                i18nStrings={{
                    overflowMenuTriggerText: "More"
                }}
            />
        </div>
    )
}

export default Header;