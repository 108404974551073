import React, { useEffect, useState } from 'react';

import {
    Box,
    Button,
    Checkbox,
    Flashbar,
    Header,
    Select,
    SpaceBetween,
    Spinner,
    StatusIndicator,
    Table,
    TextContent,
} from '@amzn/awsui-components-react/polaris';
import { getRabbitUnresponsiveBrokerQueues } from '../../../api/api';
import CopyToClipboardButton from '../../Components/copyToClipboardButton';
import { Broker, EC2Instance, RabbitBrokerQueue } from '@amzn/amazonmq-opsconsole-client';
import { BrokerInstance, RabbitBrokerQueues } from '../../../types';

type SummaryProps = {
    brokerQueues: RabbitBrokerQueues | undefined,
    onStateToggle: (queueState: string) => void,
    enabledStates: string[]
}

function getCountByState(brokerQueues: RabbitBrokerQueues | undefined): Map<string, number> {
    if (brokerQueues === undefined) {
        return new Map();
    }

    let countByState = new Map<string, number>();
    for (let i = 0; i < brokerQueues.queues.length; i++) {
        let queue = brokerQueues.queues[i];
        if (!countByState.get(queue.state)) {
            countByState.set(queue.state, 1);
        } else {
            let currentCount : number = countByState.get(queue.state) || 0;
            countByState.set(queue.state, currentCount + 1);
        }
    }
    return countByState;

}

const BrokerQueuesSummary : React.FC<SummaryProps> = ({brokerQueues, enabledStates, onStateToggle}) => {
    let countByState = getCountByState(brokerQueues);
    return (
        <div style={{display: 'flex', gap: 8, flexDirection: 'row', alignItems: 'center'}}>
            {[...countByState].map(([queueState, numberOfQueues]) => {
                return (
                    <div>
                        <Checkbox key={`queue-state-checkbox-${queueState}`} checked={enabledStates.indexOf(queueState) >= 0} onChange={(e) => {
                            onStateToggle(queueState)
                        }}>{queueState} ({numberOfQueues})</Checkbox>
                    </div>
                )
            })}
        </div>
    )
}

type Props = {
    broker: Broker,
    brokerInstances: EC2Instance[] | undefined
}


const BrokerUnresponsiveQueues : React.FC<Props> = ({broker, brokerInstances}) => {

    const [selectedInstance, setSelectedInstance] = useState<BrokerInstance | undefined>(undefined);
    const [brokerQueues, setBrokerQueues] = useState<RabbitBrokerQueue[] | undefined>(undefined);
    const [refreshCnt, setRefreshCnt] = useState<number>(0);
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (selectedInstance === undefined) {
            return;
        }
        setLoading(true);
        setError("");
        getRabbitUnresponsiveBrokerQueues(broker.id, selectedInstance.instanceId).then(response => {
            setBrokerQueues(response.unresponsiveQueues);
        }).catch(error => {
            console.error(error);
            setError("Could not load unresponsive queues");
        }).finally(() => {
            setLoading(false);
        })
    }, [broker.id, refreshCnt, selectedInstance?.instanceId])

    useEffect(() => {
        if (brokerInstances && brokerInstances.length > 0) {
            setSelectedInstance(brokerInstances[0]);
        }
    }, [brokerInstances])

    let copyText = brokerQueues !== undefined ? JSON.stringify(brokerQueues) : "";

    if (brokerInstances === undefined) {
        return <Spinner />
    }

    return (<div>
            <div style={{marginBottom: 8}}>
                <TextContent><p>Instance Id:</p></TextContent>
                <Select
                    options={[...brokerInstances.map(i => {
                        return {
                            value: i.instanceId,
                            id: i.instanceId,
                        }
                    })]}
                    selectedOption={{
                        value: selectedInstance?.instanceId,
                        label: selectedInstance?.instanceId,
                    }}
                    onChange={(event: any) => {
                        if (brokerInstances === undefined) {
                            return;
                        }
                        setSelectedInstance(brokerInstances.find(i => i.instanceId === event.detail.selectedOption.value))
                    }}
                />
            </div>
        {error && <div style={{marginTop: 8}}><Flashbar items={
            [{
                "type": "error",
                "dismissible": false,
                "content": error
            }]
        }></Flashbar></div>}
        {!error &&
            <div style={{display: 'flex', flexDirection: 'column', gap: 8}}>
                <Table
                    header={
                        <Header actions={
                            <SpaceBetween size='s' direction='horizontal'>
                                {!loading && <CopyToClipboardButton copyButtonText='Copy' variant='button' text={copyText} /> }
                                <Button disabled={loading} iconName="refresh" onClick={() => {
                                    setRefreshCnt(refreshCnt + 1);
                                }} />
                            </SpaceBetween>
                        } variant="h2" counter={brokerQueues !== undefined ? `(${brokerQueues.length.toFixed()})` : ""}>
                            Unresponsive Queues
                        </Header>
                        
                    }
                    totalItemsCount={brokerQueues?.length}
                    items={brokerQueues !== undefined ? brokerQueues : []}
                    sortingColumn={{
                        sortingField: 'state'
                    }}
                    empty={brokerInstances.length === 0 ?
                            <SpaceBetween size='xs' direction='horizontal'>
                                <StatusIndicator type='info'></StatusIndicator>
                                <TextContent>No In Service Instances found</TextContent>
                            </SpaceBetween>
                        : `No unresponsive queues found`}
                    loading={loading}
                    loadingText='Loading unresponsive queues'
                    sortingDescending={true}
                    columnDefinitions={[
                        {
                            id: "name",
                            header: "Name",
                            cell: (item: RabbitBrokerQueue) => item.name
                        },
                        {
                            id: "pid",
                            header: "PID",
                            cell: (item: RabbitBrokerQueue) => item.pid
                        }
                    ]}
                />
            </div>
        }
    </div>
    );
}

export default BrokerUnresponsiveQueues;
