import { Broker } from "@amzn/amazonmq-opsconsole-client";
import { widget } from "../utils";
import { CELL_HEIGHT, CELL_WIDTH } from "./common";

export function getRabbitNlbMetrics(broker: Broker): any[] {
    let metrics: any[] = [];

    const loadBalancerMetrics = [
        { title: "Active Flow Count", name: "ActiveFlowCount", stat: "Average" },
        { title: "New Flow Count", name: "NewFlowCount", stat: "Sum" },
        { title: "Client Reset Count", name: "TCP_Client_Reset_Count", stat: "Sum" },
        { title: "Target Reset Count", name: "TCP_Target_Reset_Count", stat: "Sum" },
        { title: "ELB Reset Count", name: "TCP_ELB_Reset_Count", stat: "Sum" },
        { title: "Processed Bytes", name: "ProcessedBytes", stat: "Sum" },
        { title: "Unhealthy Routing Flow", name: "UnhealthyRoutingFlowCount", stat: "Sum" },
        { title: "Load Balancer Capacity Units", name: "ConsumedLCUs", stat: "Average" }
    ];

    const targetGroupMetrics = [
        { title: "Unhealthy Host Count", name: "UnHealthyHostCount", stat: "Maximum" }
    ];

    const startX = 0;
    const startY = 0;
    const xOffset = 12;
    const yOffset = 8;

    const addMetric = (config: any, index: number, metricType: string) => {
        const x = startX + (index % 2) * xOffset;
        const y = startY + Math.floor(index / 2) * yOffset;

        metrics.push(widget(
            config.title,
            broker.regionName,
            [createMetric(broker, config.name, config.stat, "left", metricType)],
            x,
            y,
            CELL_WIDTH,
            CELL_HEIGHT
        ));
    };

    addMetric(loadBalancerMetrics[0], 0, "LoadBalancer");  // Active Flow Count
    addMetric(loadBalancerMetrics[1], 1, "LoadBalancer");  // New Flow Count

    addMetric(targetGroupMetrics[0], 2, "TargetGroup");    // Unhealthy Host Count
    addMetric(loadBalancerMetrics[6], 3, "LoadBalancer");  // Unhealthy Routing Flow

    addMetric(loadBalancerMetrics[2], 4, "LoadBalancer");  // Client Reset Count
    addMetric(loadBalancerMetrics[4], 5, "LoadBalancer");  // ELB Reset Count
    addMetric(loadBalancerMetrics[3], 6, "LoadBalancer");  // Target Reset Count

    addMetric(loadBalancerMetrics[5], 7, "LoadBalancer");  // Processed Bytes
    addMetric(loadBalancerMetrics[7], 8, "LoadBalancer");  // Load Balancer Capacity Units

    return metrics;
}

function createMetric(
    broker: Broker,
    metricName: string,
    stat: string,
    yAxis: string,
    metricType: string
): any[] {
    const loadBalancerName = broker.brokerInfo.loadBalancer?.name;
    if (!loadBalancerName) {
        throw new Error("Load balancer name is undefined");
    }

    const metric = [
        "AWS/NetworkELB",
        metricName,
        "LoadBalancer",
        loadBalancerName,
        {
            stat,
            accountId: broker.summary.serviceAccountId,
            yAxis
        }
    ];

    if (metricType === "TargetGroup") {
        const targetGroup = ''; // Set the target group if available
        metric.splice(3, 0, "TargetGroup", targetGroup);
    }

    return metric;
}
