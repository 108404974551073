import { Broker, EC2Instance } from "@amzn/amazonmq-opsconsole-client";
import { QUEUE_TYPES, ALL_QUORUM_QUEUE_STATES, ALL_CLASSIC_QUEUE_STATES } from "../../../../types";
import { widget } from "../utils";
import { CELL_HEIGHT, CELL_WIDTH, activeNodeCountAndContainerAgeWidget } from "./common";


export function getRabbitQueuesMetrics(broker: Broker, brokerInstances: EC2Instance[]): any[] {

    let metrics = [];

    metrics.push(activeNodeCountAndContainerAgeWidget(broker, brokerInstances, 0, 0, 24, 8));
    
    metrics.push(widget(
        "Queues / Exchanges",
        broker.regionName,
        [
            queueCount(broker),
            exchangeCount(broker),
        ],
        0, // x
        8, // y
        CELL_WIDTH, // width
        CELL_HEIGHT // height
    ))

    metrics.push(widget(
        "CMQ Messages to sync",
        broker.regionName,
        [
            messagesToSync(broker)
        ],
        12, // x
        8, // y
        CELL_WIDTH, // width
        CELL_HEIGHT // height
    ))

    metrics.push(widget(
        "RaftIndexDiff (Broker)",
        broker.regionName,
        [
            raftIndexDiffMaxPerBroker(broker),
            raftIndexDiffAvgPerBroker(broker)
        ],
        12, // x 
        16, // y 
        CELL_WIDTH, // width
        CELL_HEIGHT // height
    ))


    let yOffset = 16;
    for (let i = 0; i < QUEUE_TYPES.length; i++) {
        metrics.push(queueStateMetricsWidget(
            broker, 
            QUEUE_TYPES[i],
            (i % 2) * CELL_WIDTH,
            (i / 2) * CELL_HEIGHT + yOffset,
            CELL_WIDTH,
            CELL_HEIGHT
        ))
    }

    return metrics;
}

export function queueStateMetricsWidget(broker: Broker, queueType: string, x: number, y: number, width: number, height: number): any {
    let metrics = [];
    let queueStates = queueType === "quorum" ? ALL_QUORUM_QUEUE_STATES : ALL_CLASSIC_QUEUE_STATES;
    for (let i = 0; i < queueStates.length; i++) {
        metrics.push(queueStateMetric(broker, queueStates[i], queueType))
    }
    return widget(
        `${queueType} Queue States`,
        broker.regionName,
        metrics,
        x,
        y,
        width,
        height
    );
}

export function queueStateMetric(broker: Broker, queueState: string, queueType: string) {
    return [
        "AmazonMqRabbitService",
        "QueueCount",
        "BrokerId",
        broker.id,
        "QueueState",
        queueState,
        "QueueType",
        queueType,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left",
            period: 300
        }
    ]
}

export function queueCount(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "QueueCount",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function exchangeCount(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "ExchangeCount",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function messagesToSync(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "MessagesToSync",
        "BrokerId",
        broker.id,
        {
            stat: "Minimum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left",
            period: 300
        }
    ]
}

export function raftIndexDiffMaxPerBroker(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "RaftIndexDiff",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left",
        }
    ]
}


export function raftIndexDiffAvgPerBroker(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "RaftIndexDiff",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left",
        }
    ]
}
