import React from 'react';
import { Box, Pagination, SpaceBetween, Table } from '@amzn/awsui-components-react';
import {GroupedRabbitBrokerTimelineEvent} from "../../../types";

interface LogTableContentProps {
    isDupeMode: boolean;
    loading: boolean;
    hasInteracted: boolean;
    columnDefinitions: (isDupeMode: boolean) => any;
    groupedEvents: GroupedRabbitBrokerTimelineEvent[];

}

const LogTableContent = ({
                             isDupeMode,
                             loading,
                             hasInteracted,
                             columnDefinitions,
                             groupedEvents
                         }: LogTableContentProps) => {

    return (
        <Table
            loading={loading}
            renderAriaLive={({
                                 firstIndex,
                                 lastIndex,
                                 totalItemsCount,
                             }) =>
                `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
            }
            columnDefinitions={columnDefinitions(isDupeMode)}
            items={groupedEvents}
            loadingText="Loading resources"
            resizableColumns
            pagination={<Pagination currentPageIndex={1} pagesCount={1}/>}
            empty={
                hasInteracted && (
                    <Box
                        margin={{vertical: "xs"}}
                        textAlign="center"
                        color="inherit"
                    >
                        <SpaceBetween size="m">
                            <b>No matches</b>
                        </SpaceBetween>
                    </Box>
                )
            }
        />
    )
}

export default LogTableContent;