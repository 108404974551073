import { widget } from "../utils";
import * as Arn from "@aws-sdk/util-arn-parser";
import { CELL_HEIGHT, CELL_WIDTH, activeNodeCountAndContainerAgeWidget } from './common';
import { ALL_QUORUM_QUEUE_STATES, QUEUE_TYPES } from "../../../../types";
import { Broker, EC2Instance } from "@amzn/amazonmq-opsconsole-client";

export function getRabbitBrokerMetrics(broker: Broker, brokerInstances: EC2Instance[]): any[] {
    let brokerMetrics: any[] = [];
    let region = Arn.parse(broker.brokerInfo.arn).region;

    brokerMetrics.push(activeNodeCountAndContainerAgeWidget(broker, brokerInstances, 0, 0, 24, 8));

    brokerMetrics.push(widget(
        "Ack/Confirm/Publish/Redelivery Rate",
        region,
        [
            ackRateMetric(broker),
            confirmRateMetric(broker),
            publishRateMetric(broker),
            redeliveryRateMetric(broker),
            deliverGetRateMetric(broker),
            nackRateMetric(broker)
        ],
        0, // x
        8, // y
        CELL_WIDTH,
        CELL_HEIGHT
    ))

    brokerMetrics.push(widget(
        "Message Count",
        region,
        [
            messageCountMetric(broker),
            messageReadyCountMetric(broker),
            messageUnacknowledgedCountMetric(broker),
            totalMessagePersistedCountMetric(broker),
        ],
        12, // x
        8, // y
        CELL_WIDTH,
        CELL_HEIGHT
    ))

    brokerMetrics.push(widget(
        "Max Average Message Size (in bytes)",
        region,
        [
            maxAverageMessageSizeMetric(broker)
        ],
        0, // x
        16, // y
        CELL_WIDTH,
        CELL_HEIGHT
    ))

    brokerMetrics.push(widget(
        "vHost States",
        region,
        [
            vhostMetric(broker, 'running'),
            vhostMetric(broker, 'stopped'),
            vhostMetric(broker, 'nodedown'),
            vhostMetric(broker, 'unidentified'),
            vhostMetric(broker, 'missing'),
            vhostLimit(broker),
        ],
        12, // x
        16, // y
        CELL_WIDTH,
        CELL_HEIGHT
    ))

    brokerMetrics.push(widget(
        "Consumers",
        region,
        [
            consumerCount(broker),
        ],
        0, // x
        24, // y
        CELL_WIDTH,
        CELL_HEIGHT
    ))

    brokerMetrics.push(widget(
        "Queues / Exchanges",
        region,
        [
            queueCount(broker),
            queueLimit(broker),
            exchangeCount(broker)
        ],
        12, // x
        24, // y
        CELL_WIDTH,
        CELL_HEIGHT
    ))

    brokerMetrics.push(widget(
        "Queue counts by queue type",
        region,
        QUEUE_TYPES.map(qt => queueCountByQueueTypeMetric(broker, qt)),
        0, // x
        32, // y
        CELL_WIDTH,
        CELL_HEIGHT
    ))

    brokerMetrics.push(widget(
        "Classic Queue States",
        region,
        [
            classicQueueStateMetric(broker, 'fullySynced'),
            classicQueueStateMetric(broker, 'partiallySynced'),
            classicQueueStateMetric(broker, 'noSyncedMirrors'),
            classicQueueStateMetric(broker, 'missingStateNoSyncedMirrors'),
            classicQueueStateMetric(broker, 'missingStatePartiallySynced'),
            classicQueueStateMetric(broker, 'missingStateFullySynced'),
            classicQueueStateMetric(broker, 'missingStateNoPrimary'),
            classicQueueStateMetric(broker, 'syncing'),
            classicQueueStateMetric(broker, 'down'),
            classicQueueStateMetric(broker, 'stopped'),
            classicQueueStateMetric(broker, 'crashed'),
            classicQueueStateMetric(broker, 'flow'),
            classicQueueStateMetric(broker, 'unsupportedQueueTypes'),
            classicQueueStateMetric(broker, 'unidentified')
        ],
        12, // x
        32, // y
        CELL_WIDTH,
        CELL_HEIGHT
    ))

    brokerMetrics.push(widget(
        "QQ Queue States",
        region,
        ALL_QUORUM_QUEUE_STATES.map(qs => quorumQueueStateMetric(broker, qs)),
        0, // x
        40, // y
        CELL_WIDTH,
        CELL_HEIGHT
    ))

    brokerMetrics.push(widget(
        "Connection/Channel count",
        region,
        [
            connectionCount(broker),
            connectionLimit(broker),
            channelCount(broker),
            channelLimit(broker),
        ],
        12, // x
        40, // y
        CELL_WIDTH,
        CELL_HEIGHT
    ))

    brokerMetrics.push(widget(
        "Policy Count",
        region,
        [
            totalPolicyCount(broker),
            totalOperatorPolicyCount(broker)
        ],
        0, // x
        48, // y
        CELL_WIDTH,
        CELL_HEIGHT
    ))

    brokerMetrics.push(widget(
        "LastRabbitMqDeploymentCompleted",
        region,
        [
            lastRabbitMqDeploymentCompleted(broker),
        ],
        12, // x
        48, // y
        CELL_WIDTH,
        CELL_HEIGHT
    ))

    brokerMetrics.push(widget(
        "Shovels",
        region,
        [
            shovelCountMetric(broker),
            shovelLimit(broker),
        ],
        0, // x
        56, // y
        CELL_WIDTH,
        CELL_HEIGHT
    ))

    brokerMetrics.push(widget(
        "Federations",
        region,
        [
            federationCountMetric(broker),
            federationLinksCountMetric(broker),
        ],
        12, // x
        56, // y
        CELL_WIDTH,
        CELL_HEIGHT
    ))

    return brokerMetrics;
}


export function ackRateMetric(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "AckRate",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            id: "ackrate",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function confirmRateMetric(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "ConfirmRate",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            id: "confirmrate",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}


export function publishRateMetric(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "PublishRate",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            id: "publishrate",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function redeliveryRateMetric(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "RedeliverRate",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            id: "redeliverrate",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}

export function deliverGetRateMetric(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "DeliverGetRate",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            id: "delivergetrate",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}


export function messageCountMetric(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "MessageCount",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function messageReadyCountMetric(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "MessageReadyCount",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function messageUnacknowledgedCountMetric(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "MessageUnacknowledgedCount",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}


export function totalMessagePersistedCountMetric(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "EstimatedTotalMessagePersistentCount",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function maxAverageMessageSizeMetric(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "QueueMaxAverageMessageSize",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function consumerCount(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "ConsumerCount",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function queueCount(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "QueueCount",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function queueLimit(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "QueueLimit",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function exchangeCount(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "ExchangeCount",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}

export function connectionCount(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "ConnectionCount",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}


export function connectionLimit(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "ConnectionLimit",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function channelCount(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "ChannelCount",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}

export function channelLimit(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "ChannelLimit",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}

export function totalPolicyCount(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "TotalPolicyCount",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function totalOperatorPolicyCount(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "TotalOperatorPolicyCount",
        "BrokerId",
        broker.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}


export function lastRabbitMqDeploymentCompleted(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "LastRabbitMqDeploymentCompleted",
        "BrokerId",
        broker.id,
        {
            stat: "Sum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function shovelCountMetric(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "TotalShovelCount",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function shovelLimit(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "ShovelLimit",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function federationCountMetric(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "TotalFederationCount",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function federationLinksCountMetric(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "TotalFederationLinksCount",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}

export function vhostMetric(broker: Broker, vhostState: string) {
    return [
        "AmazonMqRabbitService",
        "vHostCount",
        "BrokerId",
        broker.id,
        "vHostState",
        vhostState,
        {
            stat: "Sum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function vhostLimit(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "VhostLimit",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function nackRateMetric(broker: Broker) {
    return [
        {
            accountId: broker.summary.serviceAccountId,
            expression: "IF(DATAPOINT_COUNT(delivergetrate)>0 , delivergetrate - ackrate)",
            label: "NAckRate (GetRate - AckRate)",
            yAxis: "right"
        }
    ]
}

export function queueCountByQueueTypeMetric(broker: Broker, queueType: string) {
    return [
        "AmazonMqRabbitService",
        "QueueCountByQueueType",
        "BrokerId",
        broker.id,
        "QueueType",
        queueType,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function classicQueueStateMetric(broker: Broker, classicQueueState: string) {
    return [
        "AmazonMqRabbitService",
        "QueueCount",
        "BrokerId",
        broker.id,
        "QueueState",
        classicQueueState,
        "QueueType",
        "classic",
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function quorumQueueStateMetric(broker: Broker, quorumQueueState: string) {
    return [
        "AmazonMqRabbitService",
        "QueueCount",
        "BrokerId",
        broker.id,
        "QueueState",
        quorumQueueState,
        "QueueType",
        "quorum",
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function connectionCreatedRateMetric(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "ConnectionCreatedRate",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}


export function channelCreatedRateMetric(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "ChannelCreatedRate",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function connectionClosedRateMetric(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "ConnectionClosedRate",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}

export function channelClosedRateMetric(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "ChannelClosedRate",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}

export function queueCreatedRateMetric(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "QueueCreatedRate",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function queueDeclaredRateMetric(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "QueueDeclaredRate",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function queueDeletedRateMetric(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "QueueDeletedRate",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}

export function msgDiskReadRateMetric(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "MsgDiskReadRate",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function msgDiskWriteRateMetric(broker: Broker) {
    return [
        "AmazonMqRabbitService",
        "MsgDiskWriteRate",
        "BrokerId",
        broker.id,
        {
            stat: "Maximum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}